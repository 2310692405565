import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Saurav Bhise ', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Backend Developer & Cloud Enthusiast', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hi! I'm ",
  name: 'Saurav Bhise',
  subtitle: 'Backend Developer & Cloud Enthusiast',
  cta: 'Explore',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Hello! I\'m Saurav Bhise, a final year student at MIT-WPU pursuing Computer Science and Engineering. I have a deep passion for building robust backend solutions and exploring the exciting world of cloud computing.',
  paragraphTwo: 'As an aspiring software engineer, I am constantly striving to expand my knowledge and learn something new every day.',
  paragraphThree: 'Looking forward to working with you!',
  resume: 'https://drive.google.com/file/d/1qY2GW2POHySjMfbM1I5dPm1DrV2LtRQc/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'summaries.png',
    title: 'Research Paper Summariser & Notes Builder',
    info: 'Developed a Large Language Model and optimized pre-trained transformers to surpass current standards, creating an abstractive and extractive summarizer and notes builder web platform. This innovative solution significantly enhances the process of reading and studying research papers, enabling users to comprehend and retain information more effectively in considerably less time',
    info2: 'MERN stack, Python, Pytorch, Transformers, HuggingFace, Flask',
    url: 'https://research-paper-summariser.vercel.app/',
    repo: 'https://github.com/sauravbhise/research-paper-summariser', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'dashboard.png',
    title: 'PhD Tracker Web App',
    info: 'Developed a software portal using ReactJS, NodeJS, and Firebase to automate the lifecycle of each Ph.D. student in MIT-WPU, transforming it into a streamlined digital suite. The tool has been widely adopted, with numerous users benefitting from it.',
    info2: 'ReactJS, Firebase',
    url: 'https://phd-tracker.web.app/',
    repo: 'https://github.com/vedantkarle/phd-tracker', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'currency.png',
    title: 'Currency Tracker Web App',
    info: 'Developed a web application using ReactJS and NodeJS to track the currency rates of various countries. The tool has been widely adopted, with numerous users benefitting from it.',
    info2: 'HTML, CSS, NodeJS, Express.js',
    url: 'https://backend-mitwpu-team7.vercel.app/',
    repo: 'https://github.com/NT-Hackathon-MITWPU-Team7', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'chatapp.jpeg',
    title: 'Chat App',
    info: 'Real time chat application built using sockets.io',
    info2: 'HTML, CSS, NodeJS, Express.js, Socket.io',
    url: 'https://sauravbhise-nodejs-chatapp.herokuapp.com/',
    repo: 'https://github.com/sauravbhise/nodejs-chat-app', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Wanna get in touch? Awesome!',
  btn: '',
  email: 'sauravbhise2002@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/saurav_bhise',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://stackoverflow.com/users/13818893/sauravbhise',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/sauravbhise/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/sauravbhise',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
